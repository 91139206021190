@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 7px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: white;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        border-radius: 100vh;
        border: 3px solid #DADAE2;
        background: #DADAE2;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #f6f7ed;
    }
}

@font-face {
    font-family: "DM Sans";
    src: url("../public/fonts/DMSans-Regular.ttf");
}

@font-face {
    font-family: "DM Sans Bold";
    src: url("../public/fonts/DMSans-Bold.ttf");
}

@font-face {
    font-family: "DM Sans Medium";
    src: url("../public/fonts/DMSans-Medium.ttf");
}